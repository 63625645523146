<template>
  <div class="d-flex mt-3 justify-content-center">
    <p v-show="errorUsuario">Error obteniendo el usuario</p>

    <h3 v-show="loadingUsuario" class="text-center"><i class="fas fa-circle-notch fa-spin"></i></h3>

    <div v-if="!errorUsuario && !loadingUsuario" class="card shadow bg-light">
      <div class="card-body text-center">
        <img v-if="!altProfileImage" :src="imagenPerfil" @error="altProfileImage = true" class="card-img-top img-fluid rounded-circle" style="max-width: 200px">
        <img v-else src="../../assets/images/user.png" class="card-img-top img-fluid rounded-circle" style="max-width: 200px">
        <h1 class="mt-4 mb-4">{{ usuario.usuario }}</h1>

        <table class="table table-bordered table-responsive mt-4 text-left">
          <tr class="thead-light">
            <th>Feha de registro</th>
            <td>{{ new Date(usuario.fechaRegistro).toLocaleDateString() }}</td>
          </tr>

          <tr class="thead-light">
            <th>Nombre</th>
            <td v-if="!showEditar">{{ usuario.nombreCompleto }}</td>

            <td v-else>
              <input type="text" v-model="usuario.nombre" class="form-control">
              <input type="text" v-model="usuario.apellidos" class="form-control">
            </td>
          </tr>

          <tr class="thead-light">
            <th>Correo</th>
            <td v-if="!showEditar">{{ usuario.correo }}</td>

            <td v-else>
              <input type="email" v-model="usuario.correo" class="form-control">
            </td>
          </tr>

          <tr class="thead-light">
            <th>Código</th>
            <td>
              {{ usuario.codigo }}

              <LoadingButton @clicked="renewCode" :iconProp="'fas fa-sync-alt'" :loadingProp="loadingCode" :classProp="'btn btn-outline-success'"></LoadingButton>
            </td>
          </tr>

          <tr class="thead-light">
            <th>Usos</th>
            <td v-if="!showEditar">{{ usuario.usos }}</td>

            <td v-else>
              <input type="number" v-model="usuario.usos" class="form-control">
            </td>
          </tr>

          <tr class="thead-light">
            <th>Invitado por</th>
            <td>{{ usuario.invitadoPor }}</td>
          </tr>

          <tr class="thead-light">
            <th>Días restantes</th>

            <td v-if="usuario.diasRestantes > 10000 && !showEditar"><span class="badge badge-success">Vitalicio</span></td>

            <td v-else-if="!showEditar">{{ usuario.diasRestantes }}</td>

            <td v-else>
              <input type="number" v-model="usuario.diasRestantes" class="form-control">
            </td>

          </tr>

          <tr class="thead-light">
            <th>Activado</th>

            <td v-if="usuario.activado"><span class="badge badge-success">Si</span></td>

            <td v-else><span class="badge badge-danger">No</span></td>

          </tr>

          <tr class="thead-light">
            <th>Admin</th>

            <td v-if="usuario.admin && !showEditar"><span class="badge badge-success">Si</span></td>

            <td v-else-if="!showEditar"><span class="badge badge-danger">No</span></td>

            <td v-else>
              <input type="checkbox" v-model="usuario.admin" class="form-control">
            </td>

          </tr>

          <tr class="thead-light">
            <th>Última actividad</th>

            <td v-if="usuario.ultimaActividad === 'Hace 0 minuto/s'"><span class="badge badge-success">Online</span></td>

            <td v-else>{{ usuario.ultimaActividad }}</td>

          </tr>
        </table>

        <div v-show="!showEditar">
          <button type="button" @click="showEditar = !showEditar" class="btn m-1 btn-info" title="Editar"><i class="fas fa-edit"></i> Editar</button>

          <LoadingButton v-if="usuario.activado" @clicked="bloquearUsuario" :iconProp="'fas fa-ban'" :loadingProp="loadingBloquear" :classProp="'btn btn-warning'" :textProp="'Bloquear'" class="m-1"></LoadingButton>

          <LoadingButton v-else @clicked="activarUsuario" :iconProp="'fas fa-check'" :loadingProp="loadingBloquear" :classProp="'btn btn-success'" :textProp="'Activar'" class="m-1"></LoadingButton>

          <LoadingButton @clicked="eliminarUsuario" :iconProp="'fas fa-trash-alt'" :loadingProp="loadingEliminar" :classProp="'btn btn-danger'" :textProp="'Eliminar'" class="m-1"></LoadingButton>
        </div>

        <div v-show="showEditar">
          <LoadingButton @clicked="editarUsuario()" :iconProp="'fas fa-check'" :loadingProp="loadingEditar" :classProp="'btn btn-outline-success'"></LoadingButton>
          <button type="button" @click="cancelarEdicion" class="btn m-1 btn-outline-danger" title="Cancelar"><i class="fas fa-times"></i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import config from '../../config'
  import LoadingButton from '../LoadingButton.vue'
  import Swal from 'sweetalert2'

  export default {
    data() {
      return {
        errorUsuario: false,
        loadingUsuario: true,
        loadingCode: false,
        loadingEditar: false,
        loadingBloquear: false,
        loadingEliminar: false,
        showEditar: false,
        usuario: null,
        altProfileImage: false
      }
    },
    computed: {
      imagenPerfil() {
        return `https://bemag.app/Users/${this.usuario.embyId}/Images/Primary?width=200`
      }
    },
    methods: {
      async getUsuario(embyId) {
        try {
          this.loadingUsuario = true
          const res = await axios.get(config.apiUrl + '/usuarios?embyId=' + embyId)
          this.loadingUsuario = false
          this.usuario = res.data
        } catch (e) {
          this.loadingUsuario = false
          this.errorUsuario = true
        }
      },
      async renewCode() {
        try {
          this.loadingCode = true
          const res = await axios.post(config.apiUrl + '/usuarios/codigo', {
            embyId: this.usuario.embyId
          })
          this.loadingCode = false
          this.usuario.codigo = res.data
        } catch (e) {
          this.loadingCode = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      },
      async editarUsuario() {
        try {

          this.loadingEditar = true

          this.usuario.diasRestantes = Number(this.usuario.diasRestantes)
          this.usuario.usos = Number(this.usuario.usos)

          const res = await axios.post(config.apiUrl + '/usuarios/editar', this.usuario)

          this.loadingEditar = false
          this.showEditar = false

          await Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })

          this.getUsuario(this.usuario.embyId)
        } catch (e) {
          this.loadingEditar = false
          this.showEditar = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      },
      cancelarEdicion() {
        this.getUsuario(this.usuario.embyId)
        this.showEditar = false
      },
      async bloquearUsuario() {
        try {
          this.loadingBloquear = true
          const res = await axios.post(config.apiUrl + '/usuarios/bloquear', {
            embyId: this.usuario.embyId
          })
          this.loadingBloquear = false

          Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })

          this.getUsuario(this.usuario.embyId)

        } catch (e) {
          this.loadingBloquear = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      },
      async activarUsuario() {
        try {
          this.loadingBloquear = true
          const res = await axios.post(config.apiUrl + '/usuarios/activar', {
            embyId: this.usuario.embyId
          })
          this.loadingBloquear = false

          Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })

          this.getUsuario(this.usuario.embyId)

        } catch (e) {
          this.loadingBloquear = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      },
      async eliminarUsuario() {
        try {
          this.loadingEliminar = true
          const res = await axios.post(config.apiUrl + '/usuarios/eliminar', {
            embyId: this.usuario.embyId
          })
          this.loadingEliminar = false

          await Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })

          this.$router.push({ name: 'Usuarios' })

        } catch (e) {
          this.loadingEliminar = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }
    },
    components: {
      LoadingButton
    },
    mounted() {
      this.getUsuario(this.$route.params.embyId)
    },
    beforeRouteUpdate(to, from, next) {
      this.errorUsuario = false
      this.getUsuario(to.params.embyId)
      next()
    }
  }
</script>
